import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player'
import { tsMethodSignature } from '@babel/types';
import { slide as Menu } from 'react-burger-menu'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {playing: false,videos: [],index: 0, selectedVideo: null, filteredVideos: null,filterText: null, category: null,dayTime: null, categories: ["Bobcat", "Deer", "Fox", "Cougar", "Nothing", "Car", "Truck", "Person"]};
    this.loadVideoData();
  }

  loadVideoData() {
    fetch(process.env.PUBLIC_URL + "data\\video_data.json").then(res => res.json())
    .then(
      (result) => {
        this.setState({videos: result, selectedVideo: result[0], filteredVideos: null})
      },
      (error) => {

      }
    )
  }

  setCategory(category) {
    this.setState({category: category});
  }

  setDayTime(dayTime)
  {
    if(this.state.dayTime == dayTime)
    {
      this.setState({dayTime: null});
    }
    else
    {
      this.setState({dayTime: dayTime});
    }    
  }



  getFilteredVideos() {

    let filterTexts = [];
    if(this.state.category != null)
    {
      filterTexts.push(this.state.category);
    }
    if(this.state.dayTime != null)
    {
      filterTexts.push(this.state.dayTime);
    }
    if(this.state.filterText != null && this.state.filterText != "")
    {
      filterTexts.push(this.state.filterText);
    }    
    let filteredVideos = [];
    if(filterTexts.length != 0)
    {
      this.state.videos.forEach(video => {
        let videoFound = true;
        filterTexts.forEach((filterText) => 
        {
          let foundTags = false;
          video.tags.forEach(tag => {
            if(tag.toLowerCase().indexOf(filterText.toLowerCase()) != -1)
            {
              foundTags = true;
            }
          });
          if(!foundTags)
          {
            videoFound = false;
          }
        });
        if(videoFound)
        {
          filteredVideos.push(video);
        }
      });
      return filteredVideos;
    }
    else
    {
      return this.state.videos;
    }
  }

  setCategory(category)
  {
    this.setState({index: 0});
    if(this.state.category == category)
    {
      this.setState({category: null});
    }
    else
    {
      this.setState({category: category});
    }
  }


  componentDidMount() {
  }

  componentWillUnmount() {
  }

  setSelectedVideo(video){
    this.setState({selectedVideo: video, playing: true});
  }

  setFilteredText(filterText)
  {
    this.setState({filterText: filterText});
  }

  changeIndex(change)
  {
    this.setState({index : this.state.index + change});
  }


  render() {
    const { playing, videos, selectedVideo, categories, category, dayTime, index } = this.state;
    let totalVideos = this.getFilteredVideos();
    let filteredVideos = totalVideos.slice(index, index + 10);
    let pageNumber = (index / 10) + 1;
    let totalPages = Math.floor((totalVideos.length / 10)) + 1;
    let hasLeft = index > 0;
    let hasRight = (index + 10) <  totalVideos.length;
    return (
      <div className="App">
        <div className="VideoHeader">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className={"btn btn-primary" + (dayTime == "day" ? " active" : "")}  >
              <input type="radio" name="options" id="option1" autocomplete="off" onChange={() => this.setDayTime("day")} checked={(dayTime == "day" ? "checked" : "")} />
              Day
            </label>
            <label className={"btn btn-secondary" + (dayTime == "night" ? " active" : "")} >
              <input type="radio" name="options" id="option1" autocomplete="off" onChange={() => this.setDayTime("night")} checked={(dayTime == "night" ? "checked" : "")} />
              Night
            </label>            
          </div>
        </div>
        <Menu>
          <div className="VideoNav">
            <ul>
              {categories.map(categoryName => (
                <li key={categoryName} className={categoryName == category ? "active" : ""} onClick={() => this.setCategory(categoryName)}>
                  {categoryName}
                </li>
              ))}
            </ul>
          </div>
        </Menu>
        <div className="VideoPlayer">
          {selectedVideo &&
          <ReactPlayer url={process.env.PUBLIC_URL + selectedVideo.url} controls={true} playing={playing}   />
          }
        </div>
        <div className="VideoList">
          <div className="pagination">
            <span className="PageButton LeftButton" onClick={() => { if(hasLeft) {this.changeIndex(-10)}}}>Left</span>
            <span>Page {pageNumber} of {totalPages}</span>
            <span className="PageButton RightButton" onClick={() => { if(hasRight) {this.changeIndex(10)}}}>Right</span>
          </div>
          <ul>
            {filteredVideos.map(item => (
              <li className="VideoListItem" key={item.url} onClick={() => this.setSelectedVideo(item)}>
                  <img src={item.url.replace("IMG_","img_").replace(".MP4",".mp4").replace(".mp4",".jpeg")} width="310px" height="160px"/>
              </li>
            ))}
          </ul>
          <div className="pagination">
            <span className="PageButton LeftButton" onClick={() => { if(hasLeft) {this.changeIndex(-10)}}}>Left</span>
            <span>Page {pageNumber} of {totalPages}</span>
            <span className="PageButton RightButton" onClick={() => { if(hasRight) {this.changeIndex(10)}}}>Right</span>
          </div>          
          
        </div>
        
      </div>
    );
  }
}


export default App;
